import { useEffect, useRef, useState } from "react"
import { Input } from "../Input/Input"
import photo from '../../assets/images/photo.png'
import { useForm, Controller, set } from "react-hook-form"
import { LoadingOutlined } from "@ant-design/icons";
import { Button, DatePicker, Select } from "antd"
import { EnterpriseActions } from "../../services/Enterprise/EnterpriseSlice"
import { Document, Image as PdfImage, Page, PDFViewer, Text, View } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux"
import { SummaryActions } from "../../services/Summary/SummarySlice"
import { LoginActions } from "../../services/Login/LoginSlices";
import blanco from '../../assets/images/blanco.png'
import moment from "moment";


export const FormSummary = ({ data, setOption }) => {
    const [images, setImages] = useState([{ "id": 1 }])
    const [imagesCountCreated, setImagesCountCreated] = useState(1)
    const [image, setImage] = useState(photo)
    const canvas = useRef([])
    const [pdf, setPdf] = useState(false)
    const [components, setComponents] = useState([])
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const { moduleWorks, enterprise, idUser, personInfo, allCoordinatorsAndBosses, userAndDependencies, fullUser } = useSelector(state => state.Login)
    const { lastSummaryNumber, loadingCreateSummary, summaryCreated } = useSelector(state => state.Summary)
    const { moduleWork } = useSelector(state => state.Area)
    const { Option } = Select
    const dispatch = useDispatch()
    console.log(data)
    useEffect(() => {
        dispatch(SummaryActions.getLastSummaryNumber(moduleWork[0].moduleWork.id))
        dispatch(LoginActions.getPersonInfo(idUser))
        dispatch(LoginActions.getAllCoordinatorsAndBosses())
    }, [])

    useEffect(() => {
        if (lastSummaryNumber.max) {
            resetSummary({
                ...watchSummary(),
                summaryNumber: lastSummaryNumber.max + 1
            });
        } else {
            resetSummary({
                ...watchSummary(),
                summaryNumber: "1"
            });
        }
    }, [lastSummaryNumber]);

    const {
        register: registerSummary,
        handleSubmit: handleSubmitSummary,
        setValue: setValueSummary,
        watch: watchSummary,
        control: controlSummary,
        formState: { errors: errorsSummary },
        trigger: triggerSummary,
        reset: resetSummary
    } = useForm({
        defaultValues: {
            contractNumber: data ? data.contractNumber : "",
            month: data ? moment(data.month) : "",
            summaryNumber: data ? data.summaryNumber : "",
            description: data ? data.description : "",
            observations: data ? data.observations : "",
            supervisor: data ? data.supervisor : "",
        }
    })

    useEffect(() => {
        canvas.current.map((d, i) => {
            var ctx = d.getContext('2d')
            var img = new Image()

            d.width = 350
            d.height = 280

            img.onload = function () {
                ctx.drawImage(img, 0, 0, 350, 280)
            }
            var dataImg;
            setTimeout(function () { dataImg = d.toDataURL() }, 1000)
            img.src = photo
        })
    }, [canvas])

    const uploadImage = (e, id) => {
        setImage(URL.createObjectURL(e.target.files[0]));

        var ctx = canvas.current[id].getContext('2d')
        var img = new Image()

        canvas.current[id].width = 350
        canvas.current[id].height = 280

        img.onload = function () {
            ctx.drawImage(img, 0, 0, 350, 280)
        }
        var dataImg;
        setTimeout(function () { dataImg = canvas.current[id].toDataURL() }, 1000)
        img.src = URL.createObjectURL(e.target.files[0])

        setTimeout(function () { setValueSummary(`image${id}`, dataImg) }, 1000)
    };

    useEffect(() => {
        enterprise && dispatch(EnterpriseActions.getEnterpriseImage(enterprise.id))
    }, [enterprise])

    useEffect(() => {
        let aux = []
        summaryCreated && summaryCreated.summaryCaseReport.map(d => {
            if (d.caseReport.reportComponent && d.caseReport.reportComponent.length > 0) {
                aux.push({ "equipment": d.caseReport.equipment, "reportComponents": d.caseReport.reportComponent })
            }
        })
        summaryCreated && dispatch(LoginActions.getUserAndDependencies(watchSummary('supervisor')))
        setComponents(aux)
        if (summaryCreated) {
            setOption('search')
        }
    }, [summaryCreated])

    let emails = ''
    if (userAndDependencies) {
        userAndDependencies.dependencyUser.map((d) => {
            emails += d.dependency.email !== null ? d.dependency.email + ',' : ""
        })
    }

    /* const MyDocument = () => (
        <Document>
            <Page orientation="landscape" size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
                <View fixed style={{ border: '3px solid #000', marginTop: '5%', marginBottom: '1.8%', width: '95%', height: '6%', flexDirection: 'row' }}>
                    <View style={{ width: '20%', alignItems: 'center', justifyContent: 'center', borderRight: '3px solid #000', flexDirection: 'row' }}>
                        <PdfImage style={{ width: '40%', height: '40%', opacity: 0.7, marginRight: '3%' }} src={enterpriseImage[0].image}></PdfImage>
                        <Text style={{ fontSize: '18px', width: '56%', opacity: 0.7 }}>{enterprise.name}</Text>
                    </View>
                    <View style={{ width: '60%', borderRight: '3px solid #000' }}>
                        <View style={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '28px', fontWeight: '700' }}>INFORME DE INGENIERÍA BIOMEDICA</Text></View>
                    </View>
                    <View style={{ width: '10%', alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000' }}>
                        <View style={{ height: '25%', width: '100%', borderBottom: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '15px' }}>VERSION</Text></View>
                        <View style={{ height: '25%', width: '100%', borderBottom: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '15px' }}>VERSION</Text></View>
                        <View style={{ height: '25%', width: '100%', borderBottom: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '15px' }}>CODIGO</Text></View>
                        <View style={{ height: '25%', alignItems: 'center', justifyContent: 'center' }}></View>
                    </View>
                    <View style={{ width: '10%', alignItems: 'center', justifyContent: 'center' }}>
                        <View style={{ height: '25%', width: '100%', borderBottom: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}></View>
                        <View style={{ height: '25%', width: '100%', borderBottom: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}></View>
                        <View style={{ height: '25%', width: '100%', borderBottom: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text>{summaryCreated && summaryCreated.summary.summaryNumber}</Text></View>
                        <View style={{ height: '25%', alignItems: 'center', justifyContent: 'center' }}></View>
                    </View>
                </View>

                <View style={{ border: '8px solid #000', width: '95%', height: '57%', flexDirection: 'column' }}>
                    <View style={{ borderBottom: '8px solid #000', width: '100%', height: '8%', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: "#95b3d7" }}><Text style={{ fontSize: '26px' }}>INFORME MENSUAL DE INGENIERÍA BIOMEDICA</Text></View>
                    <View style={{ borderBottom: '3px solid #000', width: '100%', height: '8%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '1%' }}>CONTRATO NÚMERO: {summaryCreated && summaryCreated.summary.contractNumber}</Text></View>

                    <View style={{ borderBottom: '3px solid #000', width: '100%', height: '16%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <View style={{ borderRight: '3px solid #000', width: '16%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>FECHA: </Text></View>
                        <View style={{ borderRight: '3px solid #000', width: '7%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <View style={{ borderBottom: '3px solid #000', width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>DIA </Text></View>
                            <View style={{ width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>{summaryCreated && new Date(summaryCreated.summary.dateCreated).getDate()} </Text></View>
                        </View>
                        <View style={{ borderRight: '3px solid #000', width: '7%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <View style={{ borderBottom: '3px solid #000', width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>MES </Text></View>
                            <View style={{ width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>{summaryCreated && new Date(summaryCreated.summary.dateCreated).getMonth() + 1} </Text></View>
                        </View>
                        <View style={{ borderRight: '3px solid #000', width: '7%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <View style={{ borderBottom: '3px solid #000', width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>AÑO </Text></View>
                            <View style={{ width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>{summaryCreated && new Date(summaryCreated.summary.dateCreated).getFullYear()}</Text></View>
                        </View>
                        <View style={{ borderRight: '3px solid #000', width: '36.5%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <View style={{ borderBottom: '3px solid #000', width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px' }}>DEPARTAMENTO </Text></View>
                            <View style={{ width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px' }}>FLORENCIA </Text></View>
                        </View>
                        <View style={{ width: '36.5%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <View style={{ borderBottom: '3px solid #000', width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px' }}>CIUDAD </Text></View>
                            <View style={{ width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px' }}>CAQUETA </Text></View>
                        </View>
                    </View>

                    <View style={{ borderBottom: '8px solid #000', width: '100%', height: '12%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <View style={{ borderRight: '8px solid #000', width: '40%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>CLIENTE: {enterprise.name} </Text></View>
                        <View style={{ width: '60%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ fontSize: '26px', marginLeft: '3%' }}>DIRECCIÓN: AVENIDA CIRCUNVALAR CALLE 4 BARRIO LAS MALVINAS</Text></View>
                    </View>
                    <View style={{ borderBottom: '8px solid #000', width: '100%', height: '12%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <View style={{ borderRight: '8px solid #000', width: '40%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>NIT: E.S.E HOSPITAL MALVINAS HECTOR OROZCO OROZCO </Text></View>
                        <View style={{ width: '60%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '3%' }}>TELEFONO: 4352100 EXT 115 </Text></View>
                    </View>
                    <View style={{ borderBottom: '3px solid #000', width: '100%', height: '12%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <View style={{ borderRight: '8px solid #000', width: '40%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>CONTACTO: {userAndDependencies && userAndDependencies.person.name + " " + userAndDependencies.person.secondName + " " + userAndDependencies.person.lastName + " " + userAndDependencies.person.secondLastName}</Text></View>
                        <View style={{ width: '60%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '3%' }}>CELULAR: {userAndDependencies && userAndDependencies.person.phone}</Text></View>
                    </View>
                    <View style={{ borderBottom: '3px solid #000', width: '100%', height: '12%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <View style={{ borderRight: '8px solid #000', width: '40%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>CARGO: {userAndDependencies && userAndDependencies.position}</Text></View>
                        <View style={{ width: '60%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ fontSize: '26px', marginLeft: '3%' }}>EMAIL: {emails}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '20%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                        <Text style={{ marginLeft: '1%', fontSize: '23px' }}>Se agradece la oportunidad que nos brinda contratando nuestros servicios especializados de ingeniería y asesoría Biomédica, para trabajar enfocados en cubrir las necesidades de su institución y garantizar la seguridad de sus pacientes.  ¡Tú calidad es nuestra prioridad!
                        </Text>
                        <Text style={{ marginTop: '1.2%', marginLeft: '1%', fontSize: '23px' }}>A continuación, nos permitimos darles a conocer los hallazgos y recomendaciones generales de las visitas de mantenimiento, para realizar mantenimientos preventivos, correctivos, predictivos, entrega de repuestos, asistencia técnica vía telefónica y bajas de equipos biomédicos. Y así tener en óptimo estado los equipos biomédicos y a la vez puedan brindar calidad en el servicio. Con el fin de apoyar su gestión en la administración de la tecnología biomédica de su institución </Text>
                    </View>
                </View>

                <View style={{ marginTop: '3%', border: '8px solid #000', borderBottom: '0p', width: '95%', minHeight: '80px', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: "#95b3d7" }}><Text style={{ fontSize: '28px' }}>DESCRIPCIÓN DE LAS ACTIVIDADES Y HALLAZGOS</Text></View>
                <View style={{ border: '8px solid #000', borderBottom: '0', width: '95%', minHeight: '270px', alignItems: 'flex-start', justifyContent: 'flex-start', paddingBottom: '0' }}><Text style={{ fontSize: '28px', marginTop: '1%', marginLeft: '0.4%' }}>{summaryCreated && summaryCreated.summary.description}</Text></View>
                <View style={{ border: '8px solid #000', width: '95%', minHeight: '80px', alignItems: 'flex-start', justifyContent: 'flex-start', paddingBottom: '0' }}><Text style={{ fontSize: '28px', marginTop: '0.8%', marginLeft: '0.4%' }}>Observaciones: {summaryCreated && summaryCreated.summary.observations}</Text></View>

                <View style={{ backgroundColor: "#95b3d7", marginTop: '2%', border: '6px solid #000', width: '95%', height: '100px', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '28px', fontWeight: 'bold' }}>RELACIÓN DE EQUIPOS A LOS QUE SE LE REALIZO MANTENIMIENTO CORRECTIVO EN EL {enterpriseImage[0].name}</Text>
                </View>

                <View style={{ marginTop: '2%', border: '3px solid #000', width: '95%', height: '5%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Sede</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Dependencia</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Ubicación</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Nombre Equipo</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Marca</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Serie</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Placa</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Fecha Mantenimiento</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Tipo Mantenimiento</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Servicio Ejecutado</Text></View>
                    <View style={{ width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Foto</Text></View>
                </View>
                {summaryCreated && summaryCreated.summaryCaseReport && summaryCreated.summaryCaseReport.map((caser, index) => {
                    let { brand, serie, image } = caser.equipment.biomedicEquipment ||
                        caser.equipment.industrialEquipment ||
                        caser.equipment.networkingAndCommunicationsEquipment ||
                        caser.equipment.peripheralsAndAccessoriesEquipment ||
                        caser.equipment.computersEquipment
                    if (!brand) {
                        brand = caser.equipment.computersEquipment.brandAllInOne ||
                            caser.equipment.computersEquipment.brandCPU ||
                            caser.equipment.computersEquipment.brandLaptop
                    }
                    if (!serie) {
                        serie = caser.equipment.computersEquipment.serieAllInOne ||
                            caser.equipment.computersEquipment.serieCPU ||
                            caser.equipment.computersEquipment.serieLaptop
                    }
                    let solution = (caser.biomedicCaseReport && caser.biomedicCaseReport.descriptionAnswer) ||
                        (caser.systemCaseReport && caser.systemCaseReport.solutionDelivered);
                    let serviceType = (caser.biomedicCaseReport && caser.biomedicCaseReport.serviceType) ||
                        (caser.systemCaseReport && caser.systemCaseReport.service);
                    return (
                        <View key={index} style={{ marginTop: '0%', border: '3px solid #000', borderTop: 0, width: '95%', height: '17%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                            <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{caser.equipment.headquarter ? caser.equipment.headquarter.name + " " : "Sede principal"}</Text></View>
                            <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{caser.equipment.dependency.name + " "}</Text></View>
                            <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{caser.equipment.location.name + " "}</Text></View>
                            <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{caser.equipment.name + " "}</Text></View>
                            <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{brand + " "}</Text></View>
                            <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{serie + " "}</Text></View>
                            <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{caser.equipment.licensePlate !== '' ? caser.equipment.licensePlate + ' ' : caser.equipment.biomedicEquipment.active + ' '}</Text></View>
                            <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{new Date(caser.date).getDate() + "/" + (new Date(caser.date).getMonth() + 1) + "/" + new Date(caser.date).getFullYear()}</Text></View>
                            <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{serviceType ? serviceType + " " : 'Pdf'}</Text></View>
                            <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '18px', marginLeft: '3%', marginTop: '2%' }}>{solution ? solution + " " : 'Pdf'}</Text></View>
                            <View style={{ width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>{image ? <PdfImage style={{ width: '100%', height: '100%' }} src={image ? image : blanco} ></PdfImage> : <Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Pdf</Text>}</View>
                        </View>)
                })}

                <View style={{ backgroundColor: "#95b3d7", marginTop: '2%', border: '6px solid #000', width: '95%', height: '5%', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '28px', fontWeight: 'bold' }}>RELACIÓN DE EQUIPOS A LOS QUE SE LE INSTALARON REPUESTOS EN EL {enterpriseImage[0].name}</Text>
                </View>

                <View style={{ marginTop: '2%', border: '3px solid #000', width: '95%', height: '5%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Sede</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Dependencia</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Ubicación</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Nombre Equipo</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Marca</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Serie</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Placa</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Cantidad</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Nombre del repuesto</Text></View>
                    <View style={{ borderRight: '3px solid #000', width: '11%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>N° de parte</Text></View>
                </View>

                {components && components.length > 0 && components.map(d => {
                    return d.reportComponents.map((c, i) => {
                        return (
                            <View key={i} style={{ marginTop: '0%', border: '3px solid #000', borderTop: 0, width: '95%', height: '17%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.headquarter ? d.equipment.headquarter.name : "Sede principal"}</Text></View>
                                <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.dependency.name}</Text></View>
                                <View style={{ borderRight: '3px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.biomedicEquipment.location}</Text></View>
                                <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.name}</Text></View>
                                <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.biomedicEquipment.brand}</Text></View>
                                <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.biomedicEquipment.serie}</Text></View>
                                <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.licensePlate + ' '}</Text></View>
                                <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{c.quantity}</Text></View>
                                <View style={{ borderRight: '3px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{c.name}</Text></View>
                                <View style={{ width: '11%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{c.partNumber}</Text></View>
                            </View>)
                    })
                })
                }

                {
                    summaryCreated && summaryCreated.summary.summaryImage &&
                    summaryCreated.summary.summaryImage.reduce((result, image, index) => {
                        if (index % 2 === 0) result.push([]);
                        result[result.length - 1].push(image);
                        return result;
                    }, []).map((group, index) => (
                        <Page orientation="landscape" size="A1" style={{ flexDirection: 'row', backgroundColor: '#fff', alignItems: 'center', justifyContent: 'space-around' }} key={index}>
                            {group.map((d, index) => (
                                <View key={index} style={{ border: '8px solid #000', marginTop: '1.4%', width: '48%', height: '90%', flexDirection: 'column' }}>
                                    <View style={{ width: '100%', height: '90%', flexDirection: 'column' }}>
                                        <View style={{ borderBottom: '8px solid #000', width: '100%', height: '5%', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: "#95b3d7" }}><Text style={{ fontSize: '28px' }}>REGISTRO FOTOGRÁFICO DEL MANTENIMIENTO</Text></View>
                                        <View style={{ borderBottom: '2.5px solid #000', width: '100%', height: '95%', alignItems: 'center', justifyContent: 'center' }}><PdfImage style={{ width: '100%', height: '100%', opacity: 1 }} src={d.image}></PdfImage></View>
                                    </View>
                                    <View style={{ backgroundColor: "#95b3d7", width: '100%', height: '10%', flexDirection: 'column' }}>
                                        <View style={{ width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '28px', marginTop: '0.8%', marginLeft: '0.4%' }}>Descripción: {d.description + " "}</Text></View>
                                    </View>
                                </View>
                            ))}
                        </Page>
                    ))
                }

                <View style={{ marginTop: '1%', marginBottom: '-3.5%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <PdfImage style={{ width: '30%', height: '100px' }} src={personInfo.person.firm ? personInfo.person.firm : blanco}></PdfImage>
                </View>
                <View style={{ marginTop: '3%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
                </View>
                <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text
                        style={{ fontSize: '20px', marginTop: '0.4%' }}>
                        {
                            personInfo.person.firm && personInfo.person.name + " " +
                            personInfo.person.secondName + " " +
                            personInfo.person.lastName + " " +
                            personInfo.person.secondLastName
                        }
                    </Text>
                </View>
                <View style={{ marginTop: '1%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text
                        style={{ fontSize: '20px', marginTop: '0.4%' }}>
                        {
                            personInfo && personInfo.position
                        }
                    </Text>
                </View>
                <View style={{ marginTop: '1%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text
                        style={{ fontSize: '20px', marginTop: '0.4%' }}>
                        {
                            personInfo && personInfo.profesion
                        }
                    </Text>
                </View>
                <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text style={{ fontSize: '29px', marginTop: '1%' }}>AGENTE</Text>
                </View>
            </Page>
        </Document >
    ) */

    return (
        <div className="FormSummary">
            {!summaryCreated && <div className="container">
                <div className="form">
                    <div className="firstDiv">
                        <div id="one">
                            <Input
                                {...registerSummary('contractNumber', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El numero contrato es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                height={'3vw'}
                                va={watchSummary("contractNumber")}
                                setData={e => setValueSummary("contractNumber", e)}
                                labelstyle='inputLabelBordered'
                                error={errorsSummary.contractNumber && errorsSummary.contractNumber.message}
                                label="N° Contrato" />
                            <div className="dateDiv">
                                <p className='label'>Mes</p>
                                <DatePicker
                                    picker='month'
                                    value={watchSummary("month")}
                                    {...registerSummary('month', {
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === '') {
                                                errors.push("El mes es requerido");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        },
                                    })}
                                    style={{ width: '15vw', height: '3vw' }}
                                    onChange={d => setValueSummary("month", d)}
                                />
                                {errorsSummary.month && <p className='errorMessage'>{errorsSummary.month.message}</p>}
                            </div>
                            {!data &&
                                <div className="selectDiv">
                                    <p className="pSupervisor">Seleccione supervisor</p>
                                    <Controller
                                        name="supervisor"
                                        control={controlSummary}
                                        rules={{
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El supervisor es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Supervisor'
                                                value={watchSummary('supervisor')}
                                                className="select"
                                            >
                                                {allCoordinatorsAndBosses && allCoordinatorsAndBosses.map(d => (
                                                    <Option key={d.id} value={d.id}>{d.person.name + " " + d.person.secondName + " " + d.person.lastName + " " + d.person.secondLastName}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    {errorsSummary.supervisor && <p className='errorMessage relativeTop'>{errorsSummary.supervisor.message}</p>}
                                </div>}
                        </div>
                        <div id="two">
                            <Input {...registerSummary('summaryNumber', {
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("El numero de informe es requerido");
                                    }
                                    if (!/^[0-9]+$/.test(value)) {
                                        errors.push("El numero de informe debe ser numerico");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            })}
                                disabled={true}
                                height={'3vw'}
                                va={watchSummary("summaryNumber")}
                                setData={e => setValueSummary("summaryNumber", e)}
                                labelstyle='inputLabelBordered'
                                error={errorsSummary.summaryNumber && errorsSummary.summaryNumber.message}
                                label="N° Informe" />
                        </div>
                    </div>
                    <Input {...registerSummary('description', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("La descripción es requerida");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                        height={'12vw'}
                        mt={'1vw'}
                        va={watchSummary("description")}
                        setData={e => setValueSummary("description", e)}
                        labelstyle='inputLabelBordered'
                        error={errorsSummary.description && errorsSummary.description.message}
                        label="Descripción De las actividades y hallazgos" textArea />
                    <Input {...registerSummary('observations', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("Observaciones son requeridas");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                        height={'12vw'}
                        mt={'1vw'}
                        va={watchSummary("observations")}
                        setData={e => setValueSummary("observations", e)}
                        labelstyle='inputLabelBordered'
                        error={errorsSummary.observations && errorsSummary.observations.message}
                        label="Observaciones" textArea />
                    {!data &&
                        <button
                            onClick={() => {
                                setImagesCountCreated(imagesCountCreated + 1)
                                setImages([...images, { "id": imagesCountCreated + 1 }])
                            }
                            }
                        >
                            Agregar imagen
                        </button>}
                    {!data &&
                        <div className='imagesDiv'>
                            {images.map((d, i) => {
                                return (
                                    <div key={d.id}>
                                        <Controller
                                            name={`image${d.id}`}
                                            control={controlSummary}
                                            rules={{
                                                required: "La imagen es requerida",
                                            }}
                                            render={({ field }) => (
                                                <div className='imageDiv'>
                                                    <canvas ref={(element) => {
                                                        if (!canvas.current) {
                                                            canvas.current = [];
                                                        }
                                                        canvas.current[d.id] = element;
                                                    }} className='canvas'>
                                                    </canvas>

                                                    <div className='imageButtons'>
                                                        <Button
                                                            onClick={() => {
                                                                setValueSummary(`image${d.id}`, undefined)
                                                                setImages(images.filter(e => e.id !== d.id))
                                                            }}
                                                            style={{
                                                                backgroundColor: '#ffff',
                                                                color: '#C4C4C4',
                                                                borderRadius: '10px',
                                                                width: '12vw',
                                                                height: '3vw',
                                                                fontSize: '1.3vw'
                                                            }}
                                                        >
                                                            Descartar
                                                        </Button>
                                                        <label className="custom-file-upload">
                                                            <input
                                                                accept="image/png, image/jpeg"
                                                                onClick={(event) => { event.target.value = null }}
                                                                onChange={e => {
                                                                    uploadImage(e, d.id);
                                                                }}
                                                                type='file'
                                                            ></input>
                                                            <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                        {errorsSummary[`image${d.id}`] && <p className='errorMessage'>{errorsSummary[`image${d.id}`].message}</p>}
                                        <Input {...registerSummary(`descriptionImage${d.id}`, {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("La descripción de la imagen es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                            textArea
                                            height={'3vw'}
                                            mt={'3vw'}
                                            va={watchSummary(`descriptionImage${d.id}`)}
                                            setData={e => setValueSummary(`descriptionImage${d.id}`, e)}
                                            labelstyle='inputLabelBordered'
                                            error={errorsSummary[`descriptionImage${d.id}`] && errorsSummary[`descriptionImage${d.id}`].message}
                                            label="Descripción" />
                                    </div>
                                )
                            })}
                        </div>}
                    <div className="lastDiv">
                        {fullUser.canCreate && <button onClick={() => handleSubmitSummary((dataSubmit) => {
                            dataSubmit.moduleWork = moduleWork[0].moduleWork.id;
                            dataSubmit.userCreator = idUser;
                            dataSubmit.id = data && data.id;
                            !data && dispatch(SummaryActions.createSummary(dataSubmit))
                            data && dispatch(SummaryActions.editSummary(dataSubmit))
                        })()}>{loadingCreateSummary ? <LoadingOutlined /> : "Guardar"}</button>}
                    </div>
                </div>
            </div>}
            {/* pdf && enterpriseImage && userAndDependencies && <PDFViewer className='pdf'>
                <MyDocument></MyDocument>
            </PDFViewer> */}
        </div>
    )
}