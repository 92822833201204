import { useEffect, useState } from "react";
import { EquipmentCard } from "../../../components/EquipmentCard/EquipmentCard";
import { EquipmentActions } from "../../../services/Equipment/EquipmentSlice";
import { TimelineActions } from "../../../services/Timeline/TimelineSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, DatePicker, Modal, Select, message } from "antd";
import { AreaActions } from "../../../services/Area/AreaSlice";
import { Input } from "../../../components/Input/Input";
import Icon, {
    PlusOutlined,
    SearchOutlined,
    LoadingOutlined,
    CloseCircleFilled,
} from "@ant-design/icons";
import { PdfAnualTimelines } from "../../../components/PdfAnualTimelines/PdfAnualTimelines";
import { Document, PDFViewer } from "@react-pdf/renderer";
import { CloseOutlined } from "@material-ui/icons";
import moment from "moment";

export const MPP = () => {
    const dispatch = useDispatch();
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const [option, setOption] = useState("Search");
    const [edit, setEdit] = useState(false);
    const [equipData, setEquipData] = useState();
    const [visibleExitSearch, setVisibleExitSearch] = useState(false);
    const [textXLSX, setTextXLSX] = useState("");
    const [textPDF, setTextPDF] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleModal1, setVisibleModal1] = useState(false);
    const [visibleModal2, setVisibleModal2] = useState(false);
    const { dependencys, fullUser } = useSelector((state) => state.Login);
    const [nameEquipToFilter, setNameEquipToFilter] = useState(false);
    const [stateToFilter, setStateToFilter] = useState(false);
    const [locationToFilter, setLocationToFilter] = useState(false);
    const [yearToFilter, setYearToFilter] = useState(false);
    const [areaSelectedToFilter, setAreaSelectedToFilter] = useState(false);
    const [dependencySelectedToFilter, setDependencySelectedToFilter] =
        useState(false);
    const [licensePlateToSearchToFilter, setLicensePlateToSearchToFilter] =
        useState(false);
    const [yearForSearchAnual, setYearForSearchAnual] = useState(false);

    const [currentPageFiltered, setCurrentPageFiltered] = useState(0);

    const {
        equipsWithTimelines,
        quantityEquipsWithTimelines,
        loadingEquipsForTimeline,
    } = useSelector((state) => state.Equipment);
    const {

        loadingTimelinesByEquipment
    } = useSelector((state) => state.Timeline);
    const { moduleWork, dependencySelected } = useSelector((state) => state.Area);
    const [areaSelected, setAreaSelected] = useState(undefined);
    const { Option } = Select;

    useEffect(() => {
        moduleWork &&
            dispatch(
                EquipmentActions.getEquipsWithTimelinesByModuleWork({
                    moduleWork: moduleWork[0].moduleWork.id,
                    take: 8,
                    page: 0,
                })
            );
    }, [dispatch, moduleWork, currentPage]);
    console.log(equipsWithTimelines)


    function padStr(i) {
        return i < 10 ? "0" + i : "" + i;
    }

    const onExitSearch = () => {
        setVisibleExitSearch(false);
        dispatch(EquipmentActions.setEquipmentsFiltered(false));
    };

    const months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];

    const pageSize = 10;
    const totalPages = Math.ceil(quantityEquipsWithTimelines / pageSize);

    const onSides = (n) => {
        if ((currentPage < totalPages - 1) & (n === 1)) {
            setCurrentPage((prev) => prev + n);
        }
        if ((currentPage > 0) & (n === -1)) {
            setCurrentPage((prev) => prev + n);
        }
    };

    const buttons = (t) => {
        if (moduleWork !== false) {
            if (t === "SearchModal") {
                setVisibleModal(true);
                setTextXLSX("");
                setTextPDF("");
                setCurrentPage(0);
                setCurrentPageFiltered(0);
            } else if (t === "SearchModalAnual") {
                setVisibleModal1(true);
                setTextXLSX("");
                setTextPDF("");
                setCurrentPage(0);
                setCurrentPageFiltered(0);
            } else {
                setOption(t);
                setEdit(false);
            }
        } else {
            message.warn("Debe elegir un módulo de trabajo");
        }
        dispatch(EquipmentActions.setIsFiltered(false));
    };

    const generateNButtons = (N, onClick, currentPage, totalPages) =>
        [...Array(N).keys()].map((i) =>
            currentPage === i ? (
                <div
                    className="radiobuttonSelected"
                    key={`Button_${i}`}
                    onClick={() => onClick(i)}
                >
                    {i + 1}
                </div>
            ) : Math.abs(i - currentPage) <= 2 ? (
                <div
                    className="radiobutton"
                    key={`Button_${i}`}
                    onClick={() => onClick(i)}
                >
                    {i + 1}
                </div>
            ) : i === totalPages - 1 ? (
                <div
                    className="radiobutton"
                    key={`Button_${i}`}
                    onClick={() => onClick(i)}
                >
                    {i + 1}
                </div>
            ) : (
                Math.abs(i - currentPage) === 3 && (
                    <div
                        className="radiobutton"
                        key={`Button_${i}`}
                        onClick={() => onClick(i)}
                    >
                        ...
                    </div>
                )
            )
        );

    const onSearch = (nameEquip, location, dependencySelected, licensePlate) => {
        setVisibleModal(false);
        dispatch(EquipmentActions.setEquipsWithTimelines(false));
        dispatch(
            EquipmentActions.getEquipsWithTimelinesByModuleWork({
                dependency: dependencySelected,
                nameEquip: nameEquip,
                location: location,
                licensePlate: licensePlate,
                take: 8,
                page: currentPageFiltered,
                moduleWork: moduleWork[0].moduleWork.id,
            })
        );
        setDependencySelectedToFilter(dependencySelected);
        setNameEquipToFilter(nameEquip);
        setLocationToFilter(location);
        setLicensePlateToSearchToFilter(licensePlate);
    };

    const onSearchAnual = (year, dp) => {
        setVisibleModal1(false);
        dispatch(EquipmentActions.setEquipsWithTimelines(false));
        dispatch(
            EquipmentActions.getEquipsWithTimelinesByModuleWork({
                dependency: dp,
                take: 8,
                page: false,
                moduleWork: moduleWork[0].moduleWork.id,
                year: year,
            })
        );
    };

    function pad(number, length) {
        var str = "" + number;
        while (str.length < length) {
            str = "0" + str;
        }
        return str;
    }

    Date.prototype.DDMMYYYY = function () {
        var yyyy = this.getFullYear().toString();
        var MM = pad(this.getMonth() + 1, 2);
        var dd = pad(this.getDate(), 2);
        return dd + "-" + MM + "-" + yyyy;
    };



    const ModalSearch = () => {
        const [nameEquip, setNameEquip] = useState(false);
        const [state, setState] = useState(false);
        const [location, setLocation] = useState(false);
        const [year, setYear] = useState(false);
        const [areaSelected, setAreaSelected] = useState(false);
        const [dependencySelected, setDependencySelected] = useState(false);
        const [licensePlateToSearch, setLicensePlateToSearch] = useState(false);

        const areas = dependencys && [
            ...new Map(dependencys.map((item) => [item.area.id, item.area])).values(),
        ];
        return (
            <Modal
                width={"44vw"}
                className="ModalSearchEquipment"
                visible={visibleModal}
                footer={null}
                onCancel={() => setVisibleModal(false)}
            >
                <p className="titleModal">Busqueda</p>
                <Input
                    setData={setNameEquip}
                    label={"Nombre del equipo"}
                    width={"35vw"}
                ></Input>
                <div className="selectsDependency">
                    <p
                        className="label"
                        style={{ marginTop: "-1vw", marginBottom: "0vw" }}
                    >
                        Area
                    </p>
                    <Select
                        className="select"
                        placeholder="Area"
                        onChange={(e) => {
                            setAreaSelected(e);
                        }}
                    >
                        {dependencys &&
                            areas.map((d) => (
                                <Option key={d.id} value={d.id}>
                                    {d.name}
                                </Option>
                            ))}
                    </Select>
                    <p
                        className="label"
                        style={{ marginTop: "1vw", marginBottom: "0vw" }}
                    >
                        Dependencia
                    </p>
                    {areaSelected && (
                        <Select
                            className="select"
                            placeholder="Dependencia"
                            onChange={(e) => setDependencySelected(e)}
                        >
                            {areaSelected &&
                                dependencys
                                    .filter((item) => item.area.id === areaSelected)
                                    .map((d) => (
                                        <Option key={d.id} value={d.id}>
                                            {d.name}
                                        </Option>
                                    ))}
                        </Select>
                    )}
                </div>
                <Input
                    setData={setLicensePlateToSearch}
                    label={"Placa del equipo"}
                    width={"35vw"}
                ></Input>
                <div className="divButton">
                    <Button
                        className="button"
                        onClick={() =>
                            onSearch(
                                nameEquip,
                                location,
                                dependencySelected,
                                licensePlateToSearch
                            )
                        }
                    >
                        <SearchOutlined className="plus" /> Buscar
                    </Button>
                </div>
            </Modal>
        );
    };

    const ModalSearch1 = () => {
        const [year, setYear] = useState(false);
        const [areaSelected, setAreaSelected] = useState(false);
        const [dp, setDependencySelected] = useState(false);

        const areas = dependencys && [
            ...new Map(dependencys.map((item) => [item.area.id, item.area])).values(),
        ];
        return (
            <Modal
                width={"44vw"}
                className="ModalSearchEquipment"
                visible={visibleModal1}
                footer={null}
                onCancel={() => setVisibleModal1(false)}
            >
                <p className="titleModal">Busqueda Anual</p>
                <DatePicker
                    className="datePicker"
                    onChange={d => {
                        setYear(d)
                    }}
                    picker="year"
                    placeholder="Selecciona el año"
                    style={{ width: '35.4vw', marginBottom: '1.4vw' }}
                />
                <div className="selectsDependency">
                    <p
                        className="label"
                        style={{ marginTop: "-1vw", marginBottom: "0vw" }}
                    >
                        Area
                    </p>
                    <Select
                        className="select"
                        placeholder="Area"
                        onChange={(e) => {
                            setAreaSelected(e);
                        }}
                    >
                        {dependencys &&
                            areas.map((d) => (
                                <Option key={d.id} value={d.id}>
                                    {d.name}
                                </Option>
                            ))}
                    </Select>
                    <p
                        className="label"
                        style={{ marginTop: "1vw", marginBottom: "0vw" }}
                    >
                        Dependencia
                    </p>
                    {areaSelected && (
                        <Select
                            className="select"
                            placeholder="Dependencia"
                            onChange={(e) => setDependencySelected(e)}
                        >
                            {areaSelected &&
                                dependencys
                                    .filter((item) => item.area.id === areaSelected)
                                    .map((d) => (
                                        <Option key={d.id} value={d.id}>
                                            {d.name}
                                        </Option>
                                    ))}
                        </Select>
                    )}
                </div>
                <div className="divButton">
                    <Button
                        className="button"
                        onClick={() => {
                            setYearForSearchAnual(year)
                            onSearchAnual(
                                year,
                                dp,
                            )
                        }
                        }
                    >
                        <SearchOutlined className="plus" /> Buscar
                    </Button>
                </div>
            </Modal>
        );
    };

    const ModalEquip = () => {
        return (
            <Modal
                className='ModalEquip'
                width={'90vw'}
                visible={true} footer={null}
                onCancel={() => {
                    setVisibleModal2(false)
                    setYearForSearchAnual(false)
                }}
            >
                <PDFViewer className='pdf'>
                    <PdfAnualTimelines equipsWithTimelines={equipsWithTimelines} enterprise={enterpriseImage} year={yearForSearchAnual}></PdfAnualTimelines>
                </PDFViewer>
            </Modal >
        )
    }

    return (
        <div className="MPP">
            <ModalSearch></ModalSearch>
            <ModalSearch1></ModalSearch1>
            <div className="buttonsSection">
                <div className="buttons">
                    <Button className="Selected" style={{ marginRight: '1vw' }} onClick={() => buttons("SearchModal")}>
                        {!loadingEquipsForTimeline ? (
                            <SearchOutlined className="plus" />
                        ) : (
                            <LoadingOutlined className="plus" />
                        )}{" "}
                        Buscar
                    </Button>
                    <Button className="Selected" onClick={() => buttons("SearchModalAnual")}>
                        {!loadingEquipsForTimeline ? (
                            <SearchOutlined className="plus" />
                        ) : (
                            <LoadingOutlined className="plus" />
                        )}{" "}
                        Buscar Anual
                    </Button>
                    {visibleExitSearch && (
                        <CloseCircleFilled
                            className="closeIcon"
                            onClick={() => onExitSearch()}
                        />
                    )}
                </div>
            </div>
            <div className="div">
                {equipsWithTimelines && !yearForSearchAnual &&
                    equipsWithTimelines.map((d, i) => (
                        <div className="EquipWithTimelines" key={d.id}>
                            <EquipmentCard
                                key={i}
                                name={d.name}
                                brand={d.biomedicEquipment.brand}
                                model={d.biomedicEquipment.modell}
                                serie={d.biomedicEquipment.serie}
                                plate={d.licensePlate}
                                location={d.biomedicEquipment.location}
                                moduleWork={d.moduleWork.id}
                                state={d.state}
                                id={d.id}
                            />
                            <div className="divTimelines">
                                <div className="columnsName">
                                    <p className="pColumnTimeline">N°</p>
                                    <p className="pColumnTimeline">Fecha del Mantenimiento</p>
                                    <p className="pColumnTimeline" style={{ marginRight: "6vw" }}>
                                        Mes
                                    </p>
                                    <p className="pColumnTimeline" style={{ marginRight: "6vw" }}>
                                        Sede
                                    </p>
                                    <p className="pColumnTimeline" style={{ marginRight: "6vw" }}>
                                        Agente
                                    </p>
                                    <p className="pColumnTimeline" style={{ marginRight: "6vw" }}>
                                        Dias restantes
                                    </p>
                                </div>
                                <hr></hr>
                                {d.timeline.length > 0 &&
                                    d.timeline
                                        .map((t, i) => (
                                            <div className="timeline" key={t.id}>
                                                <p>{i + 1}</p>
                                                <p>{String(new Date(t.month).getUTCMonth() + 1).padStart(2, "0") + "/" + new Date(t.month).getUTCFullYear()}</p>
                                                <p>{months[new Date(t.month).getUTCMonth()]}</p>
                                                <p>
                                                    {t.headquarter
                                                        ? t.headquarter.name
                                                        : "Sede principal"}
                                                </p>
                                                <p>
                                                    {t.user &&
                                                        t.user.person.name + " " + t.user.person.lastName}
                                                </p>
                                                <p>
                                                    {(moment.utc().diff(moment.utc(t.month), 'days') * -1) > 0 ? moment.utc().diff(moment.utc(t.month), 'days') * -1 : "Terminado"}
                                                </p>
                                                <CloseOutlined style={{ fontSize: '1.3vw', color: 'red', cursor: 'pointer' }} onClick={() => {
                                                    if (window.confirm("¿Está seguro que desea eliminar esta fecha?")) {
                                                        dispatch(TimelineActions.deleteTimeline({ id: t.id }))
                                                    }
                                                }} />
                                            </div>
                                        ))}
                            </div>
                        </div>
                    ))}
            </div>
            {equipsWithTimelines && equipsWithTimelines.length === 0 && !yearForSearchAnual && (
                <div className="divNoData">
                    <p>No hay datos</p>
                </div>
            )}
            {equipsWithTimelines && !yearForSearchAnual && (
                <div className="divPages">
                    <div className="showingText">
                        Mostrando {currentPage * pageSize + 1} -{" "}
                        {Math.min(
                            (currentPage + 1) * pageSize,
                            quantityEquipsWithTimelines
                        )}{" "}
                        de {quantityEquipsWithTimelines} elementos
                    </div>
                    <div className="divButtonsPages">
                        <div className="sidesButtons" onClick={() => onSides(-1)}>
                            Anterior
                        </div>
                        {/* <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                            {generateNButtons(totalPages, setCurrentPage, currentPage, totalPages)}
                        </Radio.Group> */}
                        <div className="sidesButtons" onClick={() => onSides(1)}>
                            Siguiente
                        </div>
                    </div>
                </div>
            )}
            {equipsWithTimelines && yearForSearchAnual && (
                <ModalEquip></ModalEquip>
            )}
        </div>
    );
};
